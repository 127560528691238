<script lang="ts" setup>
import { TransitionChild, TransitionRoot } from '@headlessui/vue'

/**
 * Very similar to a tooltip but we don't want the event listeners for clicking
 * outside of the tooltip so we need to make our own version
 */
const props = withDefaults(defineProps<{
  modelValue: number | null
  placement: 'left' | 'right'
  offset: number
  step: number
  totalSteps: number
}>(), {
  placement: 'left',
  offset: 0,
  step: 1,
})

defineEmits<{
  'update:model-value': [number]
}>()

const rootEl = ref<HTMLElement | null>(null)

const position = computed(() => {
  return {
    [props.placement === 'right' ? 'left' : 'right']: `${props.offset}px`,
  }
})

const arrow = computed(() => {
  return props.placement === 'left' ? 'before:right-2 -right-4' : 'before:-left-2'
})

const isOpen = computed(() => {
  return props.modelValue === props.step - 1
})

onMounted(() => {
  watch(isOpen, (open) => {
    if (open) {
      // smooth scroll to the element
      const element = rootEl.value
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, {
    immediate: true,
  })
})
</script>

<template>
  <div ref="rootEl" data-ui="UiTooltipTour" class="relative inline">
    <TransitionRoot appear :show="isOpen" as="template">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0 scale-95"
        enter-to="opacity-100 scale-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-95"
      >
        <div :style="position" class="absolute z-10 flex items-center shadow ring-1 ring-forgd-gray-300 bg-forgd-accent-900 rounded-xl">
          <div :class="arrow" class="absolute [@media(pointer:coarse)]:hidden before:-top-12 invisible before:visible before:block before:rotate-45 before:z-[-1] before:w-4 before:h-4 before:relative before:bg-forgd-primary-900" />
          <div
            class="p-4 text-pretty font-display text-left font-normal text-white text-xs space-y-2"
          >
            <slot />
            <div class="flex items-center justify-between">
              <div class="text-sm text-forgd-neutral-100">
                {{ step }} of {{ totalSteps }}
              </div>
              <div class="flex items-center gap-2">
                <UButton v-if="modelValue > 0" icon="i-heroicons-chevron-left" color="white" variant="outline" @click="$emit('update:model-value', modelValue - 1)" />
                <UButton v-if="modelValue < totalSteps - 1" icon="i-heroicons-chevron-right" color="white" variant="outline" @click="$emit('update:model-value', modelValue + 1)" />
                <UButton icon="i-heroicons-x-mark" color="white" variant="outline" @click="$emit('update:model-value', totalSteps + 1)" />
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
    </TransitionRoot>
  </div>
</template>
